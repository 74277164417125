export default class Cabin {
  constructor() {
    this.id = 0;
    this.adults = 1;
    this.children = 0;
    this.senior = false;
    this.accessible = false;

    this.category = null;
    this.availableCategories = [];
    this.type = null;
    this.title = null;
    this.totalPrice = 0;
    this.selectedFareName = "";
    this.selectedOptionText = "Non-Refundable deposit";
    this.isUpdatingDeck = false;

    this.categoryFilter = "all";
    this.selectedDeck = {
      deckNo: "",
      deckName: "",
      url: "",
      deckPlanCode: "",
    };
    this.selectedCabin = {
      cabinNo: "",
      cabinGrade: "",
      description: "",
      deckNo: "",
      positionInShip: "",
      maxOccupancy: 0,
      fareCode: "BESTRATE",
      fareDetail: null,
      releaseTime: "",
      totalPrice: 0,
      cf: 0,
      ca: 0,
      cp: 0,
      gr: 0,
      pr: 0,
      fe: 0,
    };

    this.availableCabins = [];

    this.fareCodes = [];
    this.decks = [];

    this.travelers = [];
    this.selectedBedConfiguration = null;
    this.diningOptions = [],
    this.selectedDiningOption = null;

    // this.paymentSchedule = [];
    this.breakDowns = null;
    this.guaranteedRoomPrice = 0;
    this.normalRoomPrice = 0;
    this.isGuaranteedRoom = false;

    this.reservationID = null;
    this.confirmBookingMessage = null;

    this.ppgr = "y";
    this.gtyCode = "";
    this.gtyFareCode = "";

    this.extraOptions = [];
    this.gtyOptions = [];

    this.maxRoomPrice = 0;
    this.showingDecks = [];
    this.showingCabins = [];
  }
}
