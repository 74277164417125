<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <v-overlay :value="overlay" style="z-index: 99999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </component>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";
import { useRouter } from "@/utils";
import LayoutBlank from "@/components/layouts/LayoutBlank.vue";
import LayoutContent from "@/components/layouts/LayoutContent.vue";
import LayoutContentWithoutVerticalNavMenu from "@/components/layouts/LayoutContentWithoutVerticalNavMenu.vue";
import LayoutStaffContent from "@/components/layouts/LayoutStaffContent.vue";
import LayoutAgentContent from "@/components/layouts/LayoutAgentContent.vue";

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutContentWithoutVerticalNavMenu,
    LayoutStaffContent,
    LayoutAgentContent,
  },
  setup(props, { root }) {
    const overlay = computed(() => root.$store.getters.overlay);
    const user = computed(() => root.$store.getters.user);
    const booking = computed(() => root.$store.getters.booking);
    const { route } = useRouter();

    const resolveLayout = computed(() => {
      if (route.value.name === null) return null;
      if (route.value.meta.layout === "blank") return "LayoutBlank";
      if (route.value.meta.layout === "withoutVerticalNavMenu") return "LayoutContentWithoutVerticalNavMenu";

      if (user.value) {
        if (user.value.is_staff) return "LayoutStaffContent";
        return "LayoutAgentContent";
      }

      return "LayoutContent";
    });

    watch(
      () => root.$store.getters.currentRoute,
      async (cur, prev) => {
        // const reloadThisPage = () => {
        //   root.$router
        //     .push({
        //       name: "booking",
        //       query: {
        //         t: new Date().getTime(),
        //       },
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // };

        // if (prev?.name === "booking" && cur?.name !== "manage-booking-item") {
        //   if (cur?.name === "search" && booking.value.bookingStepIndex > 0) {
        //     root.$store.commit("updateBooking", {
        //       key: "bookingStepIndex",
        //       value: booking.value.bookingStepIndex - 1,
        //     });

        //     if (booking.value.bookingStepIndex === 0) root.$store.dispatch("ReleaseAllCabins");
        //     reloadThisPage();
        //     return;
        //   }

        //   const isConfirmed = confirm(root.$t("booking.leave_message"));
        //   if (!isConfirmed) {
        //     reloadThisPage();
        //   } else {
        //     root.$store.dispatch("ReleaseAllCabins");
        //     root.$store.commit("clearBookingAndPackage");
        //   }
        // }
      },
    );

    return {
      resolveLayout,
      overlay,
    };
  },
};
</script>

<style>
.v-input__slot {
  min-height: 56px !important;
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>