import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: { name: "login" },
  },
  {
    path: "/auth/login",
    name: "login",
    component: () => import("@/components/auth/LoginAuth.vue"),
    meta: {
      guest: true,
      layout: "withoutVerticalNavMenu",
    },
  },
  {
    path: "/auth/signup/:token",
    name: "signup",
    component: () => import("@/components/auth/SignupAuth.vue"),
    meta: {
      guest: true,
      layout: "withoutVerticalNavMenu",
    },
  },
  {
    path: "/auth/signup/:token/success",
    name: "signup-success",
    component: () => import("@/components/auth/SignupSuccessAuth.vue"),
    meta: {
      guest: true,
      layout: "withoutVerticalNavMenu",
    },
  },
  {
    path: "/auth/forget-password",
    name: "forget-password",
    component: () => import("@/components/auth/ForgetPasswordAuth.vue"),
    meta: {
      guest: true,
      layout: "withoutVerticalNavMenu",
    },
  },
  {
    path: "/auth/reset-password/:token",
    name: "reset-password",
    component: () => import("@/components/auth/ResetPasswordAuth.vue"),
    meta: {
      guest: true,
      layout: "withoutVerticalNavMenu",
    },
  },
  {
    path: "/auth/change-password",
    name: "change-password",
    component: () => import("@/components/auth/ChangePasswordAuth.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/components/search/CruiseSearch.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking",
    name: "booking",
    component: () => import("@/components/booking/Booking.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reserve",
    name: "reserve",
    component: () => import("@/components/booking/BookingReserve.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/components/search/CruiseSearch.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/agent-account",
    name: "manage-agent-account",
    component: () => import("@/components/manage/AgentAccountManage.vue"),
    meta: {
      requiresAuth: true,
      isStaff: true,
    },
  },
  {
    path: "/manage/agent-account/:id",
    name: "manage-agent-account-item",
    component: () => import("@/components/manage/AgentAccountItemManage.vue"),
    meta: {
      requiresAuth: true,
      isStaff: true,
    },
  },
  {
    path: "/manage/booking",
    name: "manage-booking",
    component: () => import("@/components/manage/BookingManage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/booking/:id",
    name: "manage-booking-item",
    component: () => import("@/components/manage/BookingItemManage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/commission",
    name: "manage-commission",
    component: () => import("@/components/manage/CommissionManage.vue"),
    meta: {
      requiresAuth: true,
      isStaff: true,
    },
  },
  {
    path: "/manage/my/company",
    name: "manage-my-company",
    component: () => import("@/components/manage/my/CompanyMy.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/my/commission",
    name: "manage-my-commission",
    component: () => import("@/components/manage/my/CommissionMy.vue"),
    meta: {
      requiresAuth: true,
      isStaff: false,
      roles: ["admin", "maker"],
    },
  },
  {
    path: "/upgrading-system",
    name: "upgrading-system",
    component: () => import("@/components/SystemUnderMaintenance.vue"),
    meta: {
      // layout: "blank",
      layout: "withoutVerticalNavMenu",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/components/404NotFound.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // first load
  if (!from.name) {
    await store.dispatch("Refresh");
  }

  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }

    next({ name: "login" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isStaff === true)) {
    if (store.getters.user?.is_staff === true) {
      next();
      return;
    }

    next({ name: "dashboard" });
  } else if (to.matched.some((record) => record.meta.isStaff === false)) {
    if (store.getters.user?.is_staff === false) {
      next();
      return;
    }

    next({ name: "dashboard" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.roles)) {
    if (to.meta.roles.includes(store.getters.user?.role)) {
      next();
      return;
    }

    next({ name: "dashboard" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ name: "dashboard" });
      return;
    }
    next();
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  if (from.name === "booking" && to.name !== "manage-booking-item") {
    const isConfirmed = confirm("Do you really want to leave? Change you made may not be saved.");
    if (!isConfirmed) return;

    store.commit("setOverlay", true);

    await store.dispatch("ReleaseAllCabins");
    store.commit("clearBookingAndPackage");

    store.commit("setOverlay", false);

    next();
  } else if (from.name === "manage-booking" && to.name !== "manage-booking-item") {
    store.commit("clearBookingListCriteria");

    next();
  } else if (from.name === "manage-booking-item" && to.name !== "manage-booking") {
    store.commit("clearBookingListCriteria");

    next();
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  store.commit("setCurrentRoute", to);
  next();
});

export default router;
