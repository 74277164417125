import HttpRequest from "./http_request";

export default class B2cProvider extends HttpRequest {
  async getCruiseOptions() {
    try {
      const response = await this.get("/ajax/special/getSearchOptions");
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }

  async getCruiseItineraries(criteria, identify) {
    try {
      this.setHeader({
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${process.env.VUE_APP_B2C_ACCESS_TOKEN}`,
      });

      const response = await this.post("/api/v1/cruise-itineraries", { ...criteria, ...identify });
      return { data: response?.data, error: null };
    } catch (err) {
      if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.response?.data || err.message };
    }
  }
}
