<template>
  <v-menu v-model="menu" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="mx-3" outlined>
        <!-- <v-img :src="currentItem.image" class="my-3" contain height="20" width="20"></v-img> -->
        <span class="font-weight-bold"
          >{{ currentItem.label }}/USD: <span v-if="currentItem.rate">{{ Number(currentItem.rate).toFixed(2) }}</span
          ><span v-else>1.00</span></span
        >
      </v-btn>
    </template>
    <v-list v-for="currency in items" :key="currency.title">
      <v-list-item @click="changeOption(currency.value)">
        <v-list-item-action>
          <v-img :src="currency.image" class="my-3" contain height="25" width="25"></v-img>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="d-flex"
            ><span class="mr-auto">{{ currency.label }}</span>
            <span v-if="currency.rate">{{ Number(currency.rate).toFixed(2) }}</span
            ><span v-else>1.00</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ref, computed, onMounted } from "@vue/composition-api";

export default {
  setup(props, { root }) {
    const user = computed(() => root.$store.getters.user);
    const menu = ref(false);
    const items = ref([
      {
        value: "USD",
        label: "USD",
        image: require("@/assets/images/currencies/usd.png"),
      },
      {
        value: "THB",
        label: "THB",
        image: require("@/assets/images/currencies/thb.png"),
      },
    ]);

    const currentItem = computed(() => {
      return items.value.find((each) => each.value === user.value.local_currency?.toUpperCase()) ?? items[0];
    });

    const changeOption = async (optionValue) => {
      const { data, error } = await root.$store.dispatch("UpdateUser", {
        userId: user.value.id,
        body: {
          local_currency: optionValue,
        },
      });

      if (error) {
        root.$toast.open({
          message: error.status?.description || "Something went wrong!",
          type: "error",
          position: "top-right",
          duration: 3000,
          dismissible: true,
          onClick: () => {},
          onDismiss: () => {},
          queue: false,
          pauseOnHover: true,
        });

        return;
      }

      await root.$store.dispatch("Refresh");
    };

    onMounted(async () => {
      const { data, error } = await root.$store.dispatch(
        "GetB2CExchangeRate",
        items.value.map((each) => each.value).filter((each) => each !== "USD"),
      );

      const extractData = data?.data;
      if (extractData) {
        Object.keys(extractData).forEach((key) => {});
      }

      items.value = items.value.map((each) => {
        if (extractData[each.value]) {
          each.rate = extractData[each.value];
        }

        return each;
      });
    });

    return {
      menu,
      items,
      currentItem,
      changeOption,
    };
  },
};
</script>

<style>
</style>
