export default {
  auth: {
    login: "Login",
    email: "Email",
    password: "Password",
    confirm_password: "Confirm password",
    signup: "Sign Up",
    staff_signup: "Staff sign up",
    forget_password: "Forget password?",
    company_name: "Company name",
    company_address: "Company address",
    company_country: "Country",
    tax_id: "Tax ID",
    contact_person: "Contact Person",
    contact_person_title: "Title",
    contact_person_firstname: "Firstname",
    contact_person_lastname: "Lastname",
    tel: "Tel",
    bank_detail: "Banking detail",
    bank_description: "Your banking details will be shown in your customer invoice",
    bank_name: "Bank name (Optional)",
    account_number: "Account number (Optional)",
    account_name: "Account name (Optional)",
    company_logo: "Upload company logo",
    promptpay_qr: "Upload Promptpay QR",
    optional: "(Optional)",
    signup_submitted_title: "Sign up request submittted",
    signup_submitted_content: "Our staff will contact you to verify your qualification within 24 hours",
    staff_signup_success: "Sign up was successful",
    forgot_password: {
      title: "Reset password",
      enter_email_reset_password: "Enter email to reset the password",
      next: "Next",
      success: "Please check your email",
    },
    reset_password: {
      title: "Reset password",
      enter_new_password: "Enter new password",
      confirm_new_password: "Confirm new password",
      reset: "Reset",
      success: "Reset password was successful",
    },
    change_password: {
      title: "Change password",
      current_password: "Current password",
      new_password: "New password",
      confirm_new_password: "Confirm new password",
      submit: "Save Change",
      success: "Change password was successful",
    },
    ok: "OK",
    next: "Next",
  },
  menu: {
    change_password: "Change password",
    logout: "Logout",
    cruise_search: "Cruise search",
    dashboard: "Dashboard",
    agent_account: "Account",
    booking: "Booking",
    my_booking: "My Booking",
    my_company: "My Company",
    commission: "Commission",
  },
  manage: {
    agent_account: {
      title: "My agent's account",
      export: "Export",
      invite: "Invite",
      invitation_pending_list: "Invitation Pending List",
      invitee: "Invitee",
      invitee_email: "Email",
      invited_date: "Invited Date",
      manage: "Manage",
      headers: {
        agent_id: "Agent ID",
        agent_company_name: "Agent company name",
        register_date: "Regis. date",
        last_login: "Last login",
        last_12_months_dollar: "Last 12 mo. ($)",
        last_12_months_pax: "Last 12 mo. (pax)",
        status: "Status",
      },
      item: {
        view_mode: "View mode",
        edit_mode: "Edit mode",
        company_name: "Company name",
        company_address: "Company address",
        company_country: "Country",
        tax_id: "Tax ID",
        contact_person: "Contact Person",
        contact_person_title: "Title",
        contact_person_firstname: "Firstname",
        contact_person_lastname: "Lastname",
        tel: "Tel",
        bank_detail: "Banking detail",
        bank_description: "Your bank account for receiving the payment from your client",
        bank_name: "Bank name",
        account_number: "Account number",
        account_name: "Account name",
        config_detail: "My configuration",
        terms_and_condition_quotation: "Terms & Condition for Quotation",
        terms_and_condition_invoice: "Terms & Condition for Invoice",
        max_number_reserved_cabins: "Max. number of reserved cabin",
        commission_rate: "Commission Rate",
        local_currency: "Local Currency",
        suspended: "Suspended",
        upload_company_logo: "Upload company logo",
        upload_promptpay_qr: "Upload Promptpay QR",
        company_logo: "Company logo",
        promptpay_qr: "Promptpay QR",
        show_users_by_status: "Show Users By Status",
        agent_admin_email: "Email",
        agent_admin_status: "Status",
        add_new_user: "Add new user",
        invite: "Invite",
        edit_user: "Edit User",
        back: "Back",
        cancel: "Cancel",
        edit: "Edit",
        save_change: "Save change",
        approve: "Approve",
        reject: "Reject",
        approve_user_confirm_message: "Are you sure you want to approve this company?",
        reject_user_confirm_message: "Are you sure you want to reject this company?",
        remove_user_confirm_message: "Are you sure you want to remove this user?",
        maker_invitee_confirm_message: "Are you sure you want to invite this email for a new agent maker?",
        save_changes_success: "Save changes was successful",
      },
    },
    booking: {
      title: "My agent's booking",
      platform: "Platform",
      search: "Search",
      export: "Export",
      manage: "Manage",
      filter: {
        from: "Departure Date From",
        to: "Departure Date To",
        keyword: "Keyword",
        status: "Status",
        reservation_status: "Reservation Status",
      },
      headers: {
        reservation_date: "Res. Date",
        grouping_id: "Grouping ID",
        reservation_id: "Res. ID",
        departure_date: "Departure Date",
        ship: "Ship",
        agent_company_name: "Agent company name",
        confirmation_status: "Confirmation Status",
        total_amount: "Total amount",
        next_due: "Next Due,18:00 (UTC+07:00),Thailand time",
        amount: "Amount",
        status: "Status",
      },
      item: {
        resend_booking_confirmation: "Resend Booking Confirmation",
        booking_uid: "Promptcruise Booking ID: {id}",
        cruise_line: "Cruise Line",
        ship: "Ship",
        sailing_date: "Sailing date",
        duration: "Duration",
        nights: "{night} Nights",
        itinerary: "Itinerary",
        embarkation: "Embarkation",
        disembarkation: "Disembarkation",
        booking_id: "Booking ID",
        reservation_date: "Reservation date",
        contact: "Contact",
        tel: "Tel.",
        email: "Email:",
        room: "Room {index} Reservation Number: {reservationID}",
        get_booking_confirmation_and_payment: "Get booking confirmation",
        room_category: "Room category",
        room_category_detail: "{cabinGrade} - {description} Stateroom",
        room_number: "Room number",
        guest: "Guest {index}",
        date_of_birth: "Date of Birth",
        membership_no: "Membership no.",
        bedding_config: "Bedding Config",
        dining_time: "Dining Time",
        extra: "Extra",
        cruise_fare: "Cruise Fare",
        discount: "Discount",
        gratuities: "Gratuities",
        taxes: "Taxes",
        total: "Total",
        amend_booking: "Amend/Cancel",
        cancel_booking: "Cancel booking & Refund",
        logs_title: "Amendment / Cancellation logs",
        exchange_rate_title: "Committed Exchange Rate",
        payment_title: "Payments",
        go_to_my_booking: "Go to my booking",
        pay: "Pay",
        create_invoice: "Get an invoice and commit exchange rate",
        back: "Back",
        save_changes: "Save changes",
        overwrite: "Overwrite",
        cancel_overwrite: "Cancel Overwrite",
        logs: {
          headers: {
            res_id: "Res. ID",
            room_no: "Room No.",
            transaction_date: "Transaction date",
            status: "Status",
            agent_staff: "Agent staff",
            description: "Description",
            habo_user: "Promptcruise user",
            habo_comment: "Promptcruise Comments",
          },
        },
        exchange_rate: {
          headers: {
            commit_on: "Committed rate on",
            exchange_rate: "THB/USD",
            valid_till: "Valid till",
          },
        },
        payment: {
          headers: {
            paid: "Paid",
            res_id: "Res. ID",
            room_no: "Room No.",
            term: "Term",
            status: "Status",
            agent_staff: "Agent staff",
            due_date: "Due date",
            transaction_date: "Transaction date",
            payment_method: "Payment method",
            attachment: "Attachment",
            amount: "Amount",
            net_amount: "Net Amount",
          },
          total_paying_amount: "Total Net Amount",
          select_payment_method: "Select payment method",
          attachment: "Attachment",
          bank_transfer: "Bank Transfer",
          credit_card: "Credit Card",
          please_make_a_payment_to: "Please make a payment to",
          beneficilary_bank: "Beneficilary bank",
          account_number: "Account number",
          account_name: "Account name",
          amount: "Net Amount",
          amount_text: "฿{amountTHB} (USD{amountUSD})",
          upload_pay_slip: "Upload pay slip",
          card_detial: "Card detials",
          card_number: "Card number",
          card_exp: "Exp. MM/YY",
          card_holder_name: "Card holder name",
          action: {
            approve: "Approve",
            reject: "Reject",
          },
          otp: {
            dialog_title: "OTP Confirmation",
            resend: "Resend OTP",
            description: "This will affect for these reservation",
            effected_items: "#{reservationId} ({term})",
          },
          commit_bk: {
            title: "BK immediately?",
            body: "Would you like to change the selected booking status into “BK” at this moment?",
            bk_now: "BK NOW",
            leave_if_of: "Leave it OF",
          },
          confirm_go_back: "Are you sure you want to unsaved changes?",
          payment_request_success: "Payment request was successful",
          payment_confirm_success: "Payment confirmation was successful",
          save_changes_success: "Save changes was successful",
          cancel_payment_success: "Cancel payment was successful",
          reservation_return_status_success: "Reservation return status was successful",
        },
        invoice: {
          remark: "Remark",
          terms_and_condition: "Terms & Condition",
          download_invoice: "Download Invoice",
        },
        amendment: {
          title: "Please describe your amendment details below",
          description: "I would like to ...",
          submitted_title: "Amendment submitted",
          submitted_body: "Our staff will contact you to confirm the details  before proceed the amendment.",
          submitted_ok: "OK",
          request_details: "Request details",
          comment: "Promptcruise comments",
          recall_confirmation: "Are you sure you want to recall this ticket?",
          reject_title: "Reason of rejection",
          reject_reason: "Your request was rejected because...",
          reject_confirmation: "Are you sure you want to reject this ticket?",
          approve_title: "Preview amendment",
          approve_confirmation: "Are you sure you want to approve this ticket?",
          approve_success: "This amendment was approved.",
          approve: {
            reservation_id: "Reservation ID:",
            sailing: "Sailing:",
            room_category: "Room category:",
            room_number: "Room number:",
            from: "FROM",
            to: "TO",
            guest: "Guest {index}:",
            date_of_birth: "Date of Birth: {date}",
            nationality: "Nationality: {nationality}",
            phone_number: "Phone Number: {phone_number}",
            membership_no: "Membership no.: {no}",
            bedding_config: "Bedding Config:",
            dining_time: "Dining Time:",
            fare_code: "Fare Code",
            extra_service: "Extra Service",
            deposit: "Deposit Due date",
            balance: "Balance Due date",
            full_payment: "Due date",
            comment: "Your comment for approving this reqeust...",
            recall_success: "The ticket was recalled",
            reject_success: "The ticket was rejected",
            approve_success: "The ticket was approved",
          },
          status: {
            submitted: "Submitted",
            recalled: "Recalled",
            rejected: "Rejected",
            completed: "Completed",
          },
          action: {
            recall: "Recall",
            reject: "Reject",
            approve: "Approve",
          },
        },
        cancel: {
          cancellation_title: "Cancellation submitted",
          cancellation_body:
            "Our staff will contact you to confirm your transaction before proceed the cancelation and refund.",
          cancellation_ok: "OK",
        },
        sync: {
          confirmation: "Are you sure to overwrite new changas update for all reservations?",
          success: "Successfully sync.",
        },
        resend: {
          confirmation: "Are you sure to resend the booking confirmation email?",
          success: "Successfully resend.",
        },
      },
      back: "Back",
      submit: "Submit",
    },
    commission: {
      title: "Commission",
      search: "Search",
      export: "Export",
      view_booking: "View Booking",
      filter: {
        from: "Departure Date From",
        to: "Departure Date To",
        keyword: "Keyword",
      },
      status: {
        processing: "Processing",
        settled: "Settled",
      },
      headers: {
        departure_date: "Departure Date",
        reservation_id: "Res. ID",
        reservation_date: "Res. Date",
        booking_value: "Total Amount",
        net_ccf: "Net CCF",
        net_amount: "Net Amount",
        receivable_commission: "Receivable Comm.",
        unrealized_commission: "Unrealized Comm.",
        agent_fully_paid_on: "Agent fully paid on",
        realized_commission_usd: "Realized Comm (USD)",
        realized_commission_thb: "Realized Comm (THB)",
        agent_user: "Agent user",
      },
    },
  },
  search: {
    box: {
      destination: "Destination",
      departure_date: "Departure Date",
      adults: "Adults",
      adult_options: "{amount} Adult",
      adults_options: "{amount} Adults",
      children: "Kids (1-12 years old)",
      kid_options: "{amount} Kid",
      children_options: "{amount} Kids",
      departure_port: "Departure Port",
      departure_port_option_all: "All Port",
      duration: {
        outer_label: "Duration",
        inner_label: "Duration",
        inner_label_suffix: "nights",
        all_option_label: "Any Duration",
      },
      price: {
        outer_label: "Price",
        inner_label: "Price under",
        all_option_label: "Any Price",
      },
      cruise_line: "Cruise Line",
      cruise_line_option_all: "All Cruise",
      ship_name: "Ship Name",
      ship_name_option_all: "All Ship",
      guest_age_over_55: "Guest age over 55",
      accessible_room: "Accessible Room",
      reset_all: "Reset All",
      search: "Search",
    },
    result: {
      headers: {
        departure_date: "Departure Date",
        itinerary: "Itinerary",
        cruise_line: "Cruise Line",
        ship_name: "Ship Name",
        duration: "Duration (Nights)",
        inside: "Inside",
        ocean_view: "Ocean View",
        balcony: "Balcony",
        suite: "Suite",
      },
      body: {
        see_itineraries: "See itinerary",
        select: "Select",
      },
    },
  },
  booking: {
    leave_message: "Do you really want to leave? Change you made may not be saved.",
    confirm_back_step: "Are you sure you want to go back step?",
    package_line_1: "{title} ({night} Nights)",
    package_line_2: "{sailDate} on {shipName}",
    package_line_3: "from {departurePort}",
    grand_total: "Grand Total",
    warning: {
      unavailable_title: "Selected room unavailable",
      unavailable_room: "Room{index}: #{id}",
      select_new_room: "Select new room",
      system_error_title: "System Error",
      please_start_over_the_cruise_search: "Please start over the cruise search",
      search_new_cruise: "Search new cruise",
      guest_age_mismatch_title: "Guest age mismatch",
      guest_age_mismatch_body: "Room{roomIndex}: {message}",
      guest_age_mismatch_footer:
        "Change in guests age classification may cause changes in price, do you want to reclassify them as above?",
      adults: "{adults} Adults",
      adults_with_senior: "{adults} Adults, senior included",
      adults_kids: "{adults} Adults {kids} Kids",
      adults_kids_with_senior: "{adults} Adults {kids} Kids, senior included",
      recheck_birth_date: "Recheck birthdate",
      reclassify_all: "Reclassify all",
      guest_info_invalid_items: "Room{roomIndex}: #{roomNo} Guest {guestIndex}",
      guest_under_21_title: "One of guest age must be over 21 years",
      guest_under_18_title: "One of guest age must be over 18 years",
      is_under_21: "is under 21",
      is_under_18: "is under 18",
      guest_under_6_months_title: "Minimum guest age is 6 months",
      is_ineligible: "is ineligible",
      senior_over_55: "Minimum senior age is 55 years",
      classified_as_an_adult: "is classified as an adult",
      kids_under_12: "Maximum kids age is 12 years",
      classified_as_a_kids: "is classified a kid",
      assign_new_guest: "Assign new guest",
      select_new_extra: "Select new extra",
    },
    breadcrumbs: {
      search: "Search",
      room_location: "Room Location",
      extra: "Extra",
      guest_details: "Guest Details",
      reserve: "Reserve",
    },
    price_break_down: {
      room: "Room {index}",
      room_cabin: "Room {index} #{cabinNo}",
      adults_options: "{amount} Adults",
      children_options: "{amount} Kids",
      senior_include: ", Senior include",
      nights: "{night} nights",
      from: "from {departurePort}",
      guest: "Guest {index}",
      cruise_fare: "Cruise Fare",
      ccf: "CCF",
      nccf: "NonCCF",
      discount: "Discount",
      tax: "Tax & Fee",
      gratuities: "Gratuities",
      total: "Total",
      deposit: "Deposit",
      obc: "OBC",
      commission: "Commission",
    },
    location: {
      room: "Room",
      total: "Total",
      price_pax: "Price/Pax",
      gty_message: "You will get the best available stateroom based on availability, assigned prior to sailing.",
      new_room: "New Room #{index}",
      edit_room: "Edit Room #{index}",
      adults: "Adults",
      adult_options: "{amount} Adult",
      adults_options: "{amount} Adults",
      kids: "Kids",
      children: "Kids (1 - 12 years old)",
      kid_options: "{amount} Kid",
      children_options: "{amount} Kids",
      room_capacity: "Max {guest} guests",
      room_type: "Room Type",
      accessible_room: "Accessible Room",
      gty_categories: "{roomType} - {description}",
      all_categories: "All {roomType} Stateroom",
      confirm_remove_cabin: "Are you sure you want to remove this room?",
      add_room: "Add Room {index}",
      guest_age_over_55: "Guest age over 55",
      senior_include: ", Senior include",
      confirm_back_step: "Are you sure you want to go back step?",
    },
    extra: {
      room: "Room",
      per_room: "per room",
      confirm_remove_cabin: "Are you sure you want to remove this room?",
      max_capacity_title: "Number of selected guest exceed cabin's maximum capacity",
      select_new_room: "Select new room",
      get_quotation: "Get Quotation",
      edit_room: "Edit Room #{index}",
      adults: "Adults",
      adult_options: "{amount} Adult",
      adults_options: "{amount} Adults",
      kids: "Kids",
      children: "Kids (1 - 12 years old)",
      kid_options: "{amount} Kid",
      children_options: "{amount} Kids",
      room_capacity: "Max {guest} guests for #{cabinNo}",
      guest_age_over_55: "Guest age over 55",
      quotation: {
        remark: "Remark",
        terms_and_condition: "Terms & Condition",
        save_as_default: "Save as default",
        send_to: "Send to",
        add_email: "Add email",
        copy_link: "Copy link",
        download_quotation: "Download Quotation",
      },
      change_total_guest: {
        title: "Change in total number of guests",
        body: "The new number of guests does not fit the previously selected room category.",
        confirm: "Do you want to select new room category?",
        find_new_room: "Yes, find new room category",
        cancel_change: "No, cancel the change in number of guests",
      },
      suspended: {
        title: "Your account is suspended",
        body: "You have reached the maximum number of reserved cabin. To proceed futher reservation, please contact Promptcruise admin.",
      },
      ok: "OK",
      confirm_back_step: "Are you sure you want to go back step?",
    },
    guest: {
      guest_index: "Guest {index}",
      title: "Title",
      firstname: "First name",
      lastname: "Last name",
      email: "Email",
      tel: "Tel",
      date_of_birth: "Date of Birth",
      nationality: "Nationality",
      membership_no: "Membership no.",
      address: "Address",
      room_title: "Room {index} : {title} No.{cabinNo}",
      bedding_config: "Bedding Config",
      dining_time: "Dining time",
      optional: "Optional",
      confirm_back_step: "Are you sure you want to go back step?",
    },
    reserve: {
      title: "To confirm this booking, please hit the RESERVE button below",
      cruise_line: "Cruise Line",
      ship: "Ship",
      sailing_date: "Sailing date",
      duration: "Duration",
      nights: "{night} Nights",
      itinerary: "Itinerary",
      embarkation: "Embarkation",
      disembarkation: "Disembarkation",
      booking_id: "Booking ID",
      reservation_date: "Reservation date",
      contact: "Contact",
      tel: "Tel.",
      email: "Email:",
      room: "Room {index}",
      room_category: "Room category",
      room_category_detail: "{cabinGrade} - {description} Stateroom",
      room_number: "Room number",
      guest: "Guest {index}",
      date_of_birth: "Date of Birth",
      membership_no: "Membership no.",
      bedding_config: "Bedding Config",
      dining_time: "Dining Time",
      extra: "Extra",
      cruise_fare: "Cruise Fare",
      discount: "Discount",
      gratuities: "Gratuities",
      taxes: "Taxes",
      total: "Total",
      reserve: "Reserve",
      confirm_back_step: "Are you sure you want to go back step?",
      confirm_reserve: "Are you sure you want to reserve this booking?",
    },
    reset: "Reset",
    apply: "Apply",
    back: "Back",
    continue: "Continue",
  },
};
