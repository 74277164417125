const guestPromotionDetail = (cabin) => {
  const promotionObj = {};
  cabin.selectedCabin.fareDetail.priceBreakdown.forEach((traveler, travelerIndex) => {
    if (!traveler.promotionDetail) return;

    const _promotionDetail = JSON.parse(JSON.stringify(traveler.promotionDetail));
    _promotionDetail
      .reduce((pre, cur) => {
        if (pre.filter((each) => each.code && cur.code && each.code === cur.code).length > 0) {
          pre = pre.map((each) => {
            if (each.code === cur.code) each.amount += cur.amount; // sum duplicate code
            return each;
          });
        } else {
          pre.push(cur);
        }

        return pre;
      }, [])
      .forEach((each) => {
        if (
          promotionObj[each.code] &&
          promotionObj[each.code].amount &&
          Array.isArray(promotionObj[each.code].amount)
        ) {
          promotionObj[each.code].amount.push(
            ...Array(travelerIndex - promotionObj[each.code].amount.length).fill(0),
            each.amount,
          );
        } else {
          promotionObj[each.code] = {
            detail: each.detail,
            amount: [...Array(travelerIndex).fill(0), each.amount],
          };
        }
      });
  });

  // room location
  if (Object.keys(promotionObj).length === 0) {
    return [
      {
        code: 0,
        detail: "Discount",
        amount: cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.promotionAmount),
        total: cabin.selectedCabin.fareDetail.priceBreakdown
          .map((each) => each.promotionAmount)
          .reduce((pre, cur) => pre + cur, 0),
      },
    ];
  }

  // extra
  return Object.keys(promotionObj).map((key) => {
    return {
      code: key,
      detail: promotionObj[key].detail,
      amount: promotionObj[key].amount,
      total: promotionObj[key].amount.reduce((pre, cur) => pre + cur, 0),
    };
  });
};

const roomPromotionDetail = (cabins) => {
  const roomPromotionObj = {};
  cabins.map((cabin, cabinIndex) => {
    const promotionObj = {};
    cabin.selectedCabin.fareDetail.priceBreakdown.forEach((traveler, travelerIndex) => {
      if (!traveler.promotionDetail) return;

      const _promotionDetail = JSON.parse(JSON.stringify(traveler.promotionDetail));
      _promotionDetail
        .reduce((pre, cur) => {
          if (pre.filter((each) => each.code && cur.code && each.code === cur.code).length > 0) {
            pre = pre.map((each) => {
              if (each.code === cur.code) each.amount += cur.amount; // sum duplicate code
              return each;
            });
          } else {
            pre.push(cur);
          }

          return pre;
        }, [])
        .forEach((each) => {
          if (
            promotionObj[each.code] &&
            promotionObj[each.code].amount &&
            Array.isArray(promotionObj[each.code].amount)
          ) {
            promotionObj[each.code].amount.push(
              ...Array(travelerIndex - promotionObj[each.code].amount.length).fill(0),
              each.amount,
            );
          } else {
            promotionObj[each.code] = {
              detail: each.detail,
              amount: [...Array(travelerIndex).fill(0), each.amount],
            };
          }
        });
    });

    Object.keys(promotionObj).forEach((key) => {
      if (roomPromotionObj[key] && roomPromotionObj[key].amount && Array.isArray(roomPromotionObj[key].amount)) {
        roomPromotionObj[key].amount.push(
          ...Array(cabinIndex - roomPromotionObj[key].amount.length).fill(0),
          promotionObj[key].amount.reduce((pre, cur) => pre + cur, 0),
        );
      } else {
        roomPromotionObj[key] = {
          detail: promotionObj[key].detail,
          amount: [...Array(cabinIndex).fill(0), promotionObj[key].amount.reduce((pre, cur) => pre + cur, 0)],
        };
      }
    });
  });

  // room location
  if (Object.keys(roomPromotionObj).length === 0) {
    return [
      {
        code: 0,
        detail: "Discount",
        amount: cabins.map((cabin) => cabin.selectedCabin.fareDetail.priceDetail.promotionAmount),
        total: cabins
          .map((cabin) => cabin.selectedCabin.fareDetail.priceDetail.promotionAmount)
          .reduce((pre, cur) => pre + cur, 0),
      },
    ];
  }

  // extra
  return Object.keys(roomPromotionObj).map((key) => {
    return {
      code: key,
      detail: roomPromotionObj[key].detail,
      amount: roomPromotionObj[key].amount,
      total: roomPromotionObj[key].amount.reduce((pre, cur) => pre + cur, 0),
    };
  });
};

const guestPriceBreakDown = (cabin) => {
  return {
    cruiseFare: {
      element: cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.cruiseFare),
      total: cabin.selectedCabin.fareDetail.priceBreakdown
        .map((each) => each.cruiseFare)
        .reduce((pre, cur) => pre + cur, 0),
    },
    promotion: {
      element: guestPromotionDetail(cabin),
      total: guestPromotionDetail(cabin)
        .map((each) => each.amount)
        .reduce((pre, cur) => pre + cur, 0),
    },
    tax: {
      element: cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.taxFee),
      total: cabin.selectedCabin.fareDetail.priceBreakdown
        .map((each) => each.taxFee)
        .reduce((pre, cur) => pre + cur, 0),
    },
    gratuity: {
      element: cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.gratuity),
      total: cabin.selectedCabin.fareDetail.priceBreakdown
        .map((each) => each.gratuity)
        .reduce((pre, cur) => pre + cur, 0),
    },
    summary: {
      element: cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.grossAmount),
      total: cabin.selectedCabin.fareDetail.priceBreakdown
        .map((each) => each.grossAmount)
        .reduce((pre, cur) => pre + cur, 0),
    },
    obc: {
      element: cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.obcValue),
      total: cabin.selectedCabin.fareDetail.priceBreakdown
        .map((each) => each.obcValue)
        .reduce((pre, cur) => pre + cur, 0),
    },
  };
};

const roomPriceBreakDown = (cabins) => {
  return {
    cruiseFare: {
      element: cabins.map((cabin) =>
        cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.cruiseFare).reduce((pre, cur) => pre + cur, 0),
      ),
      total: cabins
        .map((cabin) =>
          cabin.selectedCabin.fareDetail.priceBreakdown
            .map((each) => each.cruiseFare)
            .reduce((pre, cur) => pre + cur, 0),
        )
        .reduce((pre, cur) => pre + cur, 0),
    },
    promotion: {
      element: roomPromotionDetail(cabins),
      total: roomPromotionDetail(cabins)
        .map((each) => each.amount)
        .reduce((pre, cur) => pre + cur, 0),
    },
    tax: {
      element: cabins.map((cabin) =>
        cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.taxFee).reduce((pre, cur) => pre + cur, 0),
      ),
      total: cabins
        .map((cabin) =>
          cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.taxFee).reduce((pre, cur) => pre + cur, 0),
        )
        .reduce((pre, cur) => pre + cur, 0),
    },
    gratuity: {
      element: cabins.map((cabin) =>
        cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.gratuity).reduce((pre, cur) => pre + cur, 0),
      ),
      total: cabins
        .map((cabin) =>
          cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.gratuity).reduce((pre, cur) => pre + cur, 0),
        )
        .reduce((pre, cur) => pre + cur, 0),
    },
    summary: {
      element: cabins.map((cabin) =>
        cabin.selectedCabin.fareDetail.priceBreakdown
          .map((each) => each.grossAmount)
          .reduce((pre, cur) => pre + cur, 0),
      ),
      total: cabins
        .map((cabin) =>
          cabin.selectedCabin.fareDetail.priceBreakdown
            .map((each) => each.grossAmount)
            .reduce((pre, cur) => pre + cur, 0),
        )
        .reduce((pre, cur) => pre + cur, 0),
    },
    obc: {
      element: cabins.map((cabin) =>
        cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.obcValue).reduce((pre, cur) => pre + cur, 0),
      ),
      total: cabins
        .map((cabin) =>
          cabin.selectedCabin.fareDetail.priceBreakdown.map((each) => each.obcValue).reduce((pre, cur) => pre + cur, 0),
        )
        .reduce((pre, cur) => pre + cur, 0),
    },
  };
};

export default function getPriceBreakDown(cabin) {
  return guestPriceBreakDown(cabin);
}
