<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    temporary
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="
            isStaff
              ? require('@/assets/images/logo.png')
              : user && user.organization && user.organization.company_logo_image
          "
          max-height="100px"
          max-width="100px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">{{ title }}</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link
        :title="$t('menu.cruise_search')"
        :to="{ name: 'search' }"
        :icon="icons.mdiShipWheel"
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('menu.dashboard')"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiViewDashboard"
      ></nav-menu-link>

      <nav-menu-section-title title="Manage"></nav-menu-section-title>

      <!-- Staff -->
      <div v-if="isStaff">
        <nav-menu-link
          :title="$t('menu.booking')"
          :to="{ name: 'manage-booking' }"
          :icon="icons.mdiCalendarText"
        ></nav-menu-link>
        <nav-menu-link
          :title="$t('menu.agent_account')"
          :to="{ name: 'manage-agent-account' }"
          :icon="icons.mdiFaceAgent"
        ></nav-menu-link>
      </div>
      <!-- Agent -->
      <div v-else>
        <nav-menu-link
          :title="$t('menu.my_booking')"
          :to="{ name: 'manage-booking' }"
          :icon="icons.mdiCalendarText"
        ></nav-menu-link>
      </div>

      <nav-menu-link
        v-if="isStaff"
        :title="$t('menu.commission')"
        :to="{ name: 'manage-commission' }"
        :icon="icons.mdiCurrencyUsd"
      ></nav-menu-link>
      <nav-menu-link
        v-else
        :title="$t('menu.commission')"
        :to="{ name: 'manage-my-commission' }"
        :icon="icons.mdiCurrencyUsd"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiShipWheel, mdiViewDashboard, mdiFaceAgent, mdiCalendarText, mdiCurrencyUsd } from "@mdi/js";
import { computed } from "@vue/composition-api";
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue";
import NavMenuLink from "./components/NavMenuLink.vue";
import RolesUser from "../../../constants/RolesUser";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props, { root }) {
    const user = computed(() => root.$store.getters.user);
    const isStaff = computed(() => root.$store.getters.user?.is_staff);
    const isAdmin = computed(() => root.$store.getters.user.role === RolesUser.admin);
    const isLoggedIn = computed(() => root.$store.getters.isAuthenticated);
    const isHaboAdmin = computed(() => isStaff.value && isAdmin.value);
    const isHaboMaker = computed(() => isStaff.value && !isAdmin.value);
    const isAgentAdmin = computed(() => !isStaff.value && isAdmin.value);
    const isAgentMaker = computed(() => !isStaff.value && !isAdmin.value);

    return {
      user,
      isStaff,
      isLoggedIn,
      isHaboAdmin,
      isHaboMaker,
      isAgentAdmin,
      isAgentMaker,
      logout: async () => {
        await root.$store.dispatch("LogOut");
        root.$router.push({ name: "login" });
      },
      icons: {
        mdiShipWheel,
        mdiViewDashboard,
        mdiFaceAgent,
        mdiCalendarText,
        mdiCurrencyUsd,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
