import moment from "moment";

const config = {
  momentDateFormat: "ddd, DD MMM YYYY",
  momentShortDateFormat: "D MMM YYYY",
  momentTimeFormat: "HH:mm",
};

function dateDurationFormat(date, days) {
  return moment(date).twix(moment(date).add(days, "days"), { allDay: true }).format();
}

function pluralize(value, ...args) {
  return args.length > 1 ? args[(value % 10) - 1] || args[args.length - 1] : args[0] + (value === 1 ? "" : "s");
}

/**
 * @typedef PresetItemData
 * @property {Number|String} id
 * @property {String} title
 * @property {String} slug
 * @property {Array} images
 * @property {Array} locations
 * @property {Array} tags
 * @property {Number} price
 * @property {String} currency
 * @property {Number} days
 * @property {Number} nights
 */

/**
 * @typedef ItineraryData
 * @property {Object} cities
 * @property {Object} itineraries
 * @property {Object} pois
 * @property {Object} summary
 * @property {Object} transportations
 */

function formatSpendingHours(time) {
  if (!time || time === 0) {
    return "N/A";
  }

  const h = Math.floor(time / 60);
  const m = Math.floor(time % 60);

  const hDisplay = h > 0 ? `${h} ${pluralize(h, "hr.", "hrs.")} ` : "";
  const mDisplay = m > 0 ? `${m} ${pluralize(m, "min")}` : "";

  return hDisplay + mDisplay;
}

export function parsePOIData(poi) {
  if (!poi) {
    return null;
  }

  return {
    id: poi.id,
    title: poi.name,
    description: poi.description,
    thumbnail: poi.thumbnail,
    images: poi.images,
    address: poi.address,
    city: poi.city,
    position: {
      lat: poi.lat,
      lng: poi.lng,
    },
    spendingHours: formatSpendingHours(poi.spendingTime),
    tags: poi.tags,
  };
}

/**
 * Parse each result item data
 * @param {Object} inputData
 * @returns {ResultItemData}
 */
export function parseResultItemData(inputData) {
  return {
    id: inputData.packageUid,
    title: inputData.packageDescription,
    slug: inputData.slug,
    cruisePackageCode: inputData.packageId,

    cruisingDate: dateDurationFormat(inputData.sailDate, inputData.noOfDays),
    cruisingDays: inputData.noOfDays,

    shipCode: inputData.shipCode,
    shipName: inputData.shipName,
    shipClass: inputData.shipClass,
    itineraries: inputData.itineraryDetail,
    /*
		visitingPorts: {
			code: inputData.itineraryDetail.portCode,
			name: inputData.itineraryDetail.portName,
			country: inputData.itineraryDetail.portCountry,
		},
		*/
    images: ["https://images.habocruise.com/packages/" + inputData.bgImage],
    cruiseLogo: inputData.cruiseLineLogo,
    cruiseLineName: inputData.cruiseLineName,
    cruiseLineCode: inputData.cruiseLineLogo.substring(0, 3),
    cruiseLineShortcode: inputData.cruiseLineShortcode,
    price: Math.min(
      ...[inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice].filter(
        function (el) {
          return el > 0;
        },
      ),
    ),
    //		sortByValue: Date.parse(inputData.sailDate),
    sortByValue: Date.parse(inputData.minDate),

    date: inputData.sailDate,
    sailDate: inputData.sailDate,
    departurePortCode: inputData.departurePortCode,
    departurePort: inputData.departurePortName,
    interiorPrice: inputData.interiorPrice,
    oceanViewPrice: inputData.oceanViewPrice,
    balconyPrice: inputData.balconyPrice,
    suitePrice: inputData.suitePrice,
    roomTypePrice: [inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice],

    minDate: inputData.sailDate,
    minDateId: inputData.packageUid,
    minCruisingDate: dateDurationFormat(inputData.sailDate, inputData.noOfDays),
    maxDate: inputData.sailDate,
    maxDateId: inputData.packageUid,
    maxCruisingDate: dateDurationFormat(inputData.sailDate, inputData.noOfDays),
    minPrice: Math.min(
      ...[inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice].filter(
        function (el) {
          return el > 0;
        },
      ),
    ),
    minPriceId: inputData.packageUid,
    maxPrice: Math.min(
      ...[inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice].filter(
        function (el) {
          return el > 0;
        },
      ),
    ),
    maxPriceId: inputData.packageUid,
    selectedId: inputData.packageUid,
    selectedDate: inputData.sailDate,
    selectedPrice: Math.min(
      ...[inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice].filter(
        function (el) {
          return el > 0;
        },
      ),
    ),
  };
}
/*
export function parseResultItemDataV2(inputData) {
	return {
		id: inputData.packageUid,
		title: inputData.packageDescription,
		slug: inputData.slug,
		cruisePackageCode: inputData.packageId,

		cruisingDate: dateDurationFormat(inputData.sailDate, inputData.noOfDays),
		cruisingDays: inputData.noOfDays,

		shipName: inputData.shipName,
		shipClass: inputData.shipClass,
		itineraries: inputData.itineraryDetail,
		/*
		visitingPorts: {
			code: inputData.itineraryDetail.portCode,
			name: inputData.itineraryDetail.portName,
			country: inputData.itineraryDetail.portCountry,
		},
		*/
/*
		images: ["https://images.habocruise.com/packages/"+inputData.bgImage],
		cruiseLogo: inputData.cruiseline.cruise_line_logo,
		cruiseLineName: inputData.cruiseline.cruise_line_name,
		cruiseLineCode: inputData.cruiseline.cruise_line_logo.substring(0, 3),
		price: Math.min(...[inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice].filter(function (el) { return el > 0;})),
		sortByValue: Date.parse(inputData.sailDate),
		
		date: inputData.sailDate,
		sailDate: inputData.sailDate,
		departurePortCode: inputData.departurePortCode,
		departurePort: inputData.departurePortName,
		interiorPrice: inputData.interiorPrice,
		oceanViewPrice: inputData.oceanViewPrice,
		balconyPrice: inputData.balconyPrice,
		suitePrice: inputData.suitePrice,
		roomTypePrice: [inputData.interiorPrice, inputData.oceanViewPrice, inputData.balconyPrice, inputData.suitePrice],
	};
}
*/

/**
 * Parse cabin data
 * @param {Object} inputData
 * @returns {CabinData}
 */
export function parseCabinData(inputData) {
  var availableCabins = [];
  var availableDecks = [];
  var availableCategories = [];
  var gtyOptions = [];
  var deckPlanCode = "";
  var ppgr = "";
  var gtyCode = "";
  var gtyFareCode = "";
  var gtyPrice = 0;
  var normalPrice = 0;
  var maxPrice = 0;
  const guaranteedRoomCodes = ["WS", "XB", "YO", "ZI", "W", "X", "XA", "XC", "Y", "Z", "XQ", "YQ", "ZQ"];
  inputData.forEach((category) => {
    var categoryDecks = [];
    var categoryCabins = [];
    category.decks.forEach((deck) => {
      if (!availableDecks.find((availableDeck) => availableDeck.deckNo === deck.id)) {
        // call desk-plans on proxy
        let insertIndex = deck.url.toString().indexOf("/deck-plans/");
        if (insertIndex !== -1) {
          insertIndex += 12;
          const deckPlanName = deck.url.toString().substring(insertIndex);
          deck.url = `${process.env.VUE_APP_API_BASE_URL}/v1/b2c/deck-plans/${deckPlanName}`;
        }

        availableDecks.push({
          deckNo: deck.id,
          deckName: deck.deckName,
          url: deck.url,
        });
        deckPlanCode = category.deckPlanCode;
      }
      if (!categoryDecks.find((categoryDeck) => categoryDeck.deckNo === deck.id)) categoryDecks.push(deck.id);
      deck.cabins.forEach((cabin) => {
        availableCabins.push({
          cabinNo: cabin.cabinNo,
          cabinGrade: category.categoryCode,
          description: category.categoryDescription,
          deckNo: deck.id,
          positionInShip: cabin.positionInShip,
          maxOccupancy: cabin.maxOccupancy,
          totalPrice: category.totalPrice,
          fareCode: category.fareCode,
          fareDetail: {
            fareCode: category.fareCode,
            fareDescription: category.prommotionDescription,
            priceDetail: {
              cruiseFare: Number(category.amount),
              balanceAmount: null,
              depositAmount: null,
              gratuity: Number(category.gratuity),
              promotionAmount: Math.abs(category.promotionAmount) * -1,
              taxFee: Number(category.taxAmount),
              totalPrice: Number(category.totalPrice),
            },
            priceBreakdown: category.priceBreakDowns.map((each) => {
              const breakDownObj = {
                cruiseFare: each.fareAmount,
                nccfAmount: each.nccfAmount,
                promotionAmount: Math.abs(each.promotionAmount) * -1,
                taxFee: each.taxAmount,
                gratuity: each.gratuity,
              };

              return {
                ...breakDownObj,
                grossAmount:
                  breakDownObj.cruiseFare + breakDownObj.promotionAmount + breakDownObj.taxFee + breakDownObj.gratuity,
              };
            }),
          },
          cf: 0,
          ca: 0,
          cp: 0,
          gr: 0,
          pr: 0,
          fe: 0,
          releaseTime: "",
        });
        categoryCabins.push(cabin.cabinNo);
      });
    });
    if (guaranteedRoomCodes.includes(category.categoryCode)) {
      gtyCode = category.categoryCode;
      gtyFareCode = category.fareCode;
      gtyPrice = category.totalPrice;
      gtyOptions.push({
        gtyCode: gtyCode,
        gtyFareCode: gtyFareCode,
        gtyPrice: gtyPrice,
        categoryDescription: category.categoryDescription,
        prommotionDescription: category.prommotionDescription,
        priceBreakDowns: category.priceBreakDowns,
      });
      availableCabins.push({
        deckNo: "",
        cabinNo: "GTY",
        cabinGrade: category.categoryCode,
        description: category.categoryDescription,
        //					totalPrice: category.netAmount,
        totalPrice: category.totalPrice,
        positionInShip: "",
        maxOccupancy: 0,
        fareCode: category.fareCode,
        fareDetail: null,
        cf: 0,
        ca: 0,
        cp: 0,
        gr: 0,
        pr: 0,
        fe: 0,
        releaseTime: "",
        //					bedConfigOptions: cabin.bedConfigurations
      });
    } else {
      if (categoryCabins.length > 0) {
        normalPrice =
          normalPrice == 0
            ? category.totalPrice
            : Math.min(category.totalPrice > 0 ? category.totalPrice : normalPrice, normalPrice);
        maxPrice = maxPrice == 0 ? category.totalPrice : Math.max(category.totalPrice, maxPrice);
        availableCategories.push({
          cabinGrade: category.categoryCode,
          description: category.categoryDescription,
          decks: categoryDecks,
          cabins: categoryCabins,
          price: category.totalPrice,
        });
      }
    }
    ppgr = category.ppgr;
  });
  //	console.log("inputData : "+JSON.stringify(inputData));
  return {
    deckPlanCode: deckPlanCode,
    availableCabins: availableCabins,
    decks: availableDecks,
    availableCategories: availableCategories,
    ppgr: ppgr,
    gtyCode: gtyCode,
    gtyFareCode: gtyFareCode,
    gtyPrice: gtyPrice,
    gtyOptions: gtyOptions,
    normalPrice: normalPrice,
    maxPrice: maxPrice,
  };
}

/**
 * Parse extra option
 * @param {Object} inputData
 * @returns {ExtraOptions}
 */
export function parseExtraOption(inputData, basePrice) {
  var extraOptions = [];
  var finalExtraOptions = [];
  var bestRateFound = false;
  var fareName = "";
  var basedFareName = "";
  var basedFareCode = "";
  var extraOptionFareName = "";
  var extraOptionFareNrd = false;
  inputData.bookingPrice.forEach((extraOption) => {
    extraOptionFareName = extraOption.fareDescription.replace(" NRD", "").replace(" REF", "");
    //		console.log("extraOptionFareName : " + extraOption.fareDescription + " => " + extraOptionFareName);
    extraOptionFareNrd = extraOption.fareDescription.includes(" NRD");
    if (basedFareName === "") {
      basedFareName = extraOptionFareName;
      basedFareCode = extraOption.fareCode;
    }
    extraOptions.push({
      bestRate: extraOption.totalPrice == basePrice && !bestRateFound,
      fareCode: extraOption.fareCode,
      fareDescription: extraOption.fareDescription,
      totalPrice: extraOption.totalPrice,
      perks: inputData.targetFareList.find((fareList) => fareList.fareCode === extraOption.fareCode).perks,
      obcValue: parseInt(
        inputData.targetFareList.find((fareList) => fareList.fareCode === extraOption.fareCode).obcValue,
      ),
      nrd: extraOptionFareNrd,
      depositAmount: extraOption.depositAmount,
    });
    if (extraOption.totalPrice == basePrice) {
      bestRateFound = true;
      basedFareName = extraOptionFareName;
      basedFareCode = extraOption.fareCode;
    }
  });
  extraOptions.sort((a, b) => a.totalPrice - b.totalPrice);
  extraOptions.forEach((extraOption) => {
    fareName = extraOption.fareDescription.replace(" NRD", "").replace(" REF", "");
    //		console.log("fareName : " + extraOption.fareDescription + " => " + fareName);
    if (
      !finalExtraOptions.find(
        (fareList) => fareList.fareName.toLowerCase().replace(/ /g, "") === fareName.toLowerCase().replace(/ /g, ""),
      )
    ) {
      finalExtraOptions.push({
        fareName: fareName,
        perks: extraOption.perks,
        obcValue: extraOption.obcValue,
        fareCode: extraOption.fareCode,
        farePrice: extraOption.totalPrice,
        addOnPrice: extraOption.totalPrice - basePrice,
        nrd: {
          fareCode: extraOption.nrd ? extraOption.fareCode : null,
          totalPrice: extraOption.nrd ? extraOption.totalPrice : 0,
          bestRate: extraOption.nrd ? extraOption.bestRate : false,
          depositAmount: extraOption.nrd ? extraOption.depositAmount : 0,
        },
        rd: {
          fareCode: !extraOption.nrd ? extraOption.fareCode : null,
          totalPrice: !extraOption.nrd ? extraOption.totalPrice : 0,
          bestRate: !extraOption.nrd ? extraOption.bestRate : false,
          depositAmount: !extraOption.nrd ? extraOption.depositAmount : 0,
          nrdAdditionalPrice: 0,
        },
      });
    } else {
      if (extraOption.nrd) {
        finalExtraOptions.find(
          (fareList) => fareList.fareName.toLowerCase().replace(/ /g, "") === fareName.toLowerCase().replace(/ /g, ""),
        ).nrd = {
          fareCode: extraOption.fareCode,
          totalPrice: extraOption.totalPrice,
          bestRate: extraOption.bestRate,
          depositAmount: extraOption.depositAmount,
        };
      } else {
        var targetFare = finalExtraOptions.find(
          (fareList) => fareList.fareName.toLowerCase().replace(/ /g, "") === fareName.toLowerCase().replace(/ /g, ""),
        );
        //				finalExtraOptions.find(fareList => fareList.fareName === fareName).rd = {
        targetFare.rd = {
          fareCode: extraOption.fareCode,
          totalPrice: extraOption.totalPrice,
          bestRate: extraOption.bestRate,
          depositAmount: extraOption.depositAmount,
          nrdAdditionalPrice: extraOption.totalPrice - targetFare.nrd.totalPrice,
        };
      }
    }
  });
  return {
    options: finalExtraOptions,
    basedFareName: basedFareName,
    basedFareCode: basedFareCode,
  };
}

/**
 * Parse Itinerary Plan Item Data
 * @param {Object} inputData
 * @param {Object} poiData
 */
export function parseItineraryPlanItemData(inputData, poiData) {
  let data = null;

  if (inputData.data) {
    // If inputData has data, use it.
    data = inputData.data;
  } else if (inputData.poiId) {
    // If not, get it from POI data.
    data = poiData.find((poi) => poi.id === inputData.poiId);
  }

  return {
    ...inputData,
    data,
  };
}

/**
 * Parse Itinerary Data
 * @param {ItineraryData} inputData Data from API
 */
export function parseItineraryData(inputData) {
  if (!inputData || !inputData.data) {
    return null;
  }

  return inputData.data.reduce((result, dayData) => {
    const id = dayData.day;
    const cityData = inputData.cityData.find((city) => city.day === id);

    // Parse cities data
    let cities = [];
    if (cityData && cityData.list) {
      cities = cityData.list.map((city) => ({
        title: city.name,
        position: {
          lat: city.lat,
          lng: city.lng,
        },
      }));
    }
    const formattedCities = cities.length ? cities.map((city) => city.title).join(", ") : "";

    // Parse plan data
    const plans = dayData.plans.map((item) => parseItineraryPlanItemData(item, inputData.poiData));

    // Parse readable date
    let date = null;
    if (inputData.summary.startDate) {
      date = moment(inputData.summary.startDate)
        .add(dayData.day - 1, "days")
        .format(config.momentDateFormat);
    }

    return result.concat({
      id,
      day: dayData.day,
      cities,
      formattedCities,
      date,
      plans,
    });
  }, []);
}
