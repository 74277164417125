<template>
  <v-menu v-model="menuLanguage" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon class="mx-3" fab>
        <v-img :src="flagImage" class="my-3" contain height="25" width="25"></v-img>
      </v-btn>
    </template>
    <v-list v-for="lang in languages" :key="lang.title">
      <v-list-item @click="changeLocale(lang.language)">
        <v-list-item-action>
          <v-img :src="lang.image" class="my-3" contain height="30" width="30"></v-img>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="lang.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ref, reactive, onMounted, computed } from "@vue/composition-api";
import i18n from "@/plugins/i18n";

export default {
  setup(props, { root }) {
    const menuLanguage = ref(false);
    const languages = reactive([
      {
        language: "en",
        title: "English",
        image: require("@/assets/images/languages/en.png"),
      },
      {
        language: "th",
        title: "ไทย",
        image: require("@/assets/images/languages/th.png"),
      },
    ]);

    const flagImage = computed(() => {
      if (i18n.locale === "en") return require("@/assets/images/languages/en.png");
      if (i18n.locale === "th") return require("@/assets/images/languages/th.png");

      // default
      return require("@/assets/images/languages/en.png");
    });

    const changeLocale = (locale) => {
      i18n.locale = locale;
      root.$cookies.set("locale", locale, "14d");
    };

    onMounted(() => {
      i18n.locale = root.$cookies.get("locale") || "en";
    });

    return {
      menuLanguage,
      languages,
      flagImage,
      changeLocale,
    };
  },
};
</script>

<style>
</style>
