// plugin
import "@/plugins/vue-composition-api";
import "@/plugins/vue-toast-notification";
import "@/plugins/vue-cookies";
import "@/plugins/vue-js-modal";
require('twix');

// scss
import "@/styles/styles.scss";
import "@/styles/app.scss";

// custom directive
import "@/directives/mask-directive";
// import "@/directives/click-outside-directive";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import tinybox from './plugins/vue-tinybox';
import vuePhoneNumberInput from "./plugins/vue-phone-number-input";

import axios from "axios";

import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  tinybox,
  vuePhoneNumberInput,
  render: (h) => h(App),
}).$mount("#app");

// axios.interceptors.response.use(undefined, (error) => {
//   if (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       store.dispatch("Logout");

//       return router.push({ name: "login" });
//     }
//   }
// });
