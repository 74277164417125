import HttpRequest from "./http_request";

export default class AuthProvider extends HttpRequest {
  async getWpPopularCruising() {
    try {
      const response = await this.get("/wp-json/wp/v2/popular-cruise");
      if (response?.data?.data?.status === 404)
        throw new Error(response?.data?.data?.message || "Something went wrong!");
      return { data: response?.data, error: null };
    } catch (err) {
      // if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.message };
    }
  }

  async getWpMedia(id) {
    try {
      const response = await this.get("/wp-json/wp/v2/media/" + id);
      if (response?.data?.data?.status === 404)
        throw new Error(response?.data?.data?.message || "Something went wrong!");
      return { data: response?.data, error: null };
    } catch (err) {
      // if (process.env.VUE_APP_NODE_ENV === "development") console.error(err);
      return { data: null, error: err.message };
    }
  }
}
