require('./overrides.scss')
import colors from 'vuetify/lib/util/colors'

export default {
  theme: {
    themes: {
      light: {
        primary: '#1193B1',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FEBD11',
        error: '#FF4C51',
        pink: colors.pink.base,
        white: '#FFFFFF'
      },
      dark: {
        primary: '#1193B1',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FEBD11',
        error: '#FF4C51',
        pink: colors.pink.base,
        white: '#FFFFFF'
      },
    },
  },
}
