module.exports = {
  cruiseLineShortcode(cruiseLineCode) {
    switch (cruiseLineCode.toUpperCase()) {
      case "R": return "RCL";
      case "C": return "CEL";
      default: return cruiseLineCode;
    }
  },
  formattedPriceWithSymbol(amount, currency = "USD") {
    const symbol = {
      USD: "$",
      THB: "฿",
    };

    if (!amount) amount = 0;
    return (
      (amount < 0 ? "-" : "") +
      (symbol[currency] || symbol.USD) +
      Math.abs(amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  },
};
