<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <v-img
            :src="
              (user && user.organization && user.organization.company_logo_image) ||
              require('@/assets/images/avatars/3.png')
            "
          ></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <v-img
              :src="
                (user && user.organization && user.organization.company_logo_image) ||
                require('@/assets/images/avatars/3.png')
              "
            ></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userEmail }}
          </span>
          <small class="text--disabled text-capitalize">{{ userRole }}</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>
      <!-- my company -->
      <v-list-item link :to="{ name: 'manage-my-company' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiGoogleMyBusiness }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.my_company") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- change password -->
      <v-list-item link :to="{ name: 'change-password' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.change_password") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Logout -->
      <v-list-item link @click="logout()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiHelpCircleOutline, mdiLogoutVariant, mdiGoogleMyBusiness } from "@mdi/js";
import { computed, ref } from "@vue/composition-api";

export default {
  setup(props, { root }) {
    const user = computed(() => root.$store.getters.user);
    const userEmail = computed(() => root.$store.getters.lessUserEmail);
    const userRole = computed(() => root.$store.getters.user.role);

    return {
      user,
      userEmail,
      userRole,
      logout: () => {
        root.$store.dispatch("Logout");
      },
      icons: {
        mdiHelpCircleOutline,
        mdiLogoutVariant,

        mdiGoogleMyBusiness,
      },
    };
  },
};
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
