import axios from "axios";
import router from "@/router";

class HttpRequest {
  constructor(url = process.env.VUE_APP_API_BASE_URL) {
    // this.axios = axios
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 120000,
      withCredentials: true,
    });

    // this.axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_ADDRESS;
    // this.axiosInstance.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, HEAD, POST, PUT, DELETE, CONNECT, OPTIONS, TRACE, PATCH'
    // this.axiosInstance.defaults.headers.common['Access-Control-Allow-Headers'] = 'X-Powered-By,Content-Type,Content-Length,ETag,Date,Connection,Keep-Alive'
    // this.axiosInstance.defaults.headers.common['Access-Control-Allow-Credentials'] = 'false'

    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        return config;
      },
      (error) => {
        // Do something with request error
        this.pushLog("error-request", error, error.config);
        return Promise.reject(error);
      },
    );

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        // Do something with response error
        if (error.response.status === 503 && error.response.data?.status?.description?.error === "Maintenance") {
          router.push({
            name: "upgrading-system",
            params: { apiMessage: error.response.data?.status?.description?.api_message },
          });
        }

        // check status code
        // 503
        // router.push({ name: "upgrading-system" });

        this.pushLog("error-response", error, error.config);
        return Promise.reject(error);
      },
    );
  }

  setHeader(header) {
    // this.axiosInstance.defaults.headers.common[header.key] = header.value
    this.axiosInstance.defaults.headers.common = header;
    this.axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
  }

  setResponseType(responseType) {
    this.axiosInstance.defaults.responseType = responseType;
  }

  get(methodName, data, config) {
    return this.axiosInstance.get(methodName, {
      ...config,
      params: data,
    });
  }

  post(methodName, data, config) {
    return this.axiosInstance.post(methodName, data, config);
  }

  update(methodName, data, config) {
    return this.axiosInstance.put(methodName, data, config);
  }

  delete(methodName, param, data, config) {
    return this.axiosInstance.delete(methodName, {
      ...config,
      params: param,
      data,
    });
  }

  pushLog(event, data, config) {
    // prevent loop of dead
    if (!config || config.method !== "post" || !config.url.includes("/v1/logs")) {
      this.axiosInstance.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
      return this.post("/v1/logs/" + event, data);
    }
  }

  request(type, url, data) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, { params: data });
        break;
      case "POST":
        promise = axios.post(url, data);
        break;
      case "PUT":
        promise = axios.put(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, { params: data });
        break;
    }

    return promise;
  }
}

export default HttpRequest;
