import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locales/en";
import th from "./locales/th";

Vue.use(VueI18n);

const messages = {
  en,
  th,
};

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

export default i18n;
