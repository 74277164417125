import { render, staticRenderFns } from "./LayoutAgentContent.vue?vue&type=template&id=4c0d80ae&scoped=true&"
import script from "./LayoutAgentContent.vue?vue&type=script&lang=js&"
export * from "./LayoutAgentContent.vue?vue&type=script&lang=js&"
import style0 from "./LayoutAgentContent.vue?vue&type=style&index=0&id=4c0d80ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c0d80ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VImg,VMain,VSlideXTransition,VSpacer})
