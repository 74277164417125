<template>
  <v-app>
    <vertical-nav-menu title="Promptcruise Admin" :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

          <!-- Logo -->
          <router-link v-if="!isDrawerOpen" to="/" class="d-flex align-center text-decoration-none">
            <v-img
              :src="require('@/assets/images/logo.png')"
              height="50px"
              max-width="50px"
              alt="logo"
              contain
              eager
              class="app-logo me-3"
            ></v-img>
            <v-slide-x-transition>
              <h3 class="app-title text--primary font-weight-bold">Promptcruise Admin</h3>
            </v-slide-x-transition>
          </router-link>

          <v-spacer></v-spacer>

          <currency-switcher></currency-switcher>

          <language-switcher></language-switcher>
          <!-- <theme-switcher></theme-switcher> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <layout-footer></layout-footer>
  </v-app>
</template>

<script>
import { reactive, ref, watch } from "@vue/composition-api";
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js";
import VerticalNavMenu from "./vertical-nav-menu/VerticalNavMenu.vue";
import ThemeSwitcher from "./ThemeSwitcher.vue";
import AppBarUserMenu from "./AppBarUserMenu.vue";
import LayoutFooter from "./LayoutFooter.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import CurrencySwitcher from "./CurrencySwitcher.vue";

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    LayoutFooter,
    LanguageSwitcher,
    CurrencySwitcher,
  },
  setup(props, { root }) {
    const isDrawerOpen = ref(null);
    const searchInput = ref(null);
    const searchItems = reactive(
      root.$router.options.routes
        .filter((each) => each.meta && each.meta.searchLabel)
        .sort((a, b) => {
          const fa = a.meta.searchLabel.toLowerCase();
          const fb = b.meta.searchLabel.toLowerCase();
          if (fa < fb) return -1;
          if (fa > fb) return 1;

          return 0;
        }),
    );

    const customFilter = (item, queryText) => {
      const searchText = queryText.toLowerCase();
      const keywords = item.meta.keywords.map((each) => each.toLowerCase());

      return (
        keywords.find((each) => each.indexOf(searchText) > -1) ||
        item.meta.searchLabel.toLowerCase().indexOf(searchText) > -1
      );
    };

    watch(searchInput, (value) => {
      if (!!value && root.$route.name !== value) root.$router.push({ name: value });
    });

    return {
      isDrawerOpen,
      searchInput,
      searchItems,
      customFilter,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
