<template>
  <v-footer app inset color="transparent" absolute height="56" class="px-0">
    <div class="boxed-container w-full">
      <div class="mx-6 d-flex justify-space-between">
        <span>
          &copy; {{ new Date().getFullYear() }}
          <a href="https://promptcruise.com" class="text-decoration-none" target="_blank">Promptcruise.com</a></span
        >
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  setup(props, { root }) {},
};
</script>

<style lang="scss" scoped>
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
