<template>
  <v-fade-transition mode="out-in">
    <v-icon :key="$vuetify.theme.dark" @click="themeSwitcher()">
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from "@mdi/js";
import { onMounted, computed } from "@vue/composition-api";

export default {
  setup(props, { root }) {
    const user = computed(() => root.$store.getters.user);

    onMounted(() => {
      root.$vuetify.theme.dark = root.$cookies.get("theme") === "dark";
    });

    return {
      themeSwitcher: () => {
        root.$vuetify.theme.dark = !root.$vuetify.theme.dark;
        root.$cookies.set("theme", root.$vuetify.theme.dark ? "dark" : "light", "14d");
      },
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    };
  },
};
</script>

<style>
</style>
